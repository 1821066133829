type ShareProps = {
  text?: string
  twitterHashtags?: string[]
  shareUrl?: string
}

const useSocialShareLink = () => {
  const i18n = useI18n()
  const config = useRuntimeConfig()
  const route = useRoute()
  const currentPath = computed(() => {
    const path =
      route.path.slice(-4) === '/en/'
        ? route.path.slice(0, -1)
        : route.path.slice(-1) === '/' || route.path.slice(-3) === '/en'
        ? route.path
        : `${route.path}/`
    return `${config.public.NUXT_ENV_URL}${path}`
  })

  const getShareUrl = (target: string, props?: ShareProps) => {
    const shareText =
      props?.text ??
      (i18n.locale.value === 'ja'
        ? encodeURIComponent(`${currentPath.value} をシェア`)
        : encodeURIComponent(`Share ${currentPath.value}`))

    const twitterUrlSearchParam = new URLSearchParams({
      url: props?.shareUrl ? props?.shareUrl : currentPath.value,
      text: `${shareText}\n`,
    })
    if (props?.twitterHashtags && props.twitterHashtags.length > 0) {
      twitterUrlSearchParam.set('hashtags', props.twitterHashtags.join())
    }
    const twitterURL = `https://twitter.com/intent/tweet?${twitterUrlSearchParam.toString()}`

    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${
      props?.shareUrl ? props?.shareUrl : currentPath.value
    }&t=${shareText}`
    const lineURL = `http://line.me/R/msg/text/?${shareText} ${
      props?.shareUrl || ''
    }`

    switch (target) {
      case 'twitter':
        return twitterURL
      case 'facebook':
        return facebookURL
      case 'line':
        return lineURL
      default:
        return ''
    }
  }

  return {
    getShareUrl,
  }
}

export default useSocialShareLink
